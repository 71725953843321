.text-60 {
  font-size: 60px;
}
.text-40 {
  font-size: 40px;
}
.text-30 {
  font-size: 30px;
}

.w-150 {
  width: 150px;
}

.price {
  .price-currency {
    font-size: 50%;
    font-weight: 400;
  }
  .price-cost {
    font-size: 100%;
    font-weight: 300;

  }
  .price-unit {
    font-size: 33%;
    font-weight: 500;
  }
}